<template>
    <div class="report mb-sm-10">
        <div style="background: var(--color-primary-light)">
            <Banner
                title="成績報告書簡介"
                :src-desktop="require('@/assets/report/banner.png')"
                :src-mobile="require('@/assets/report/banner-mobile.png')"
            />
            <div class="introduction">
                <div
                    class="border"
                    style="top: 0"
                />
                <div class="introduction-background" />
                <div class="introduction-content">
                    <h1 class="introduction-title">
                        NEST 學習能力健檢報告簡介
                    </h1>
                    <p class="introduction-description">
                        NEST 成績報告書，是您孩子的專屬學習健檢報告！<br>
                        透過透明、公開的全方位學科能力評估，幫助家長提前掌握孩子的學習狀況，並根據各科表現制定未來學習策略，提供孩子在學習路上最佳的支持。
                    </p>
                </div>
                <div
                    class="border"
                    style="bottom: 0"
                />
            </div>
            <section1 />
        </div>

        <v-divider style="border-color: var(--color-primary)" />
        <section2 />
        <v-divider style="border-color: var(--color-primary)" />
        <section3 />
        <v-divider style="border-color: var(--color-primary)" />
        <section4 />
        <v-divider style="border-color: var(--color-primary)" />
        <section5 />
    </div>
</template>

<script>
import Section1 from '@/components/report/section1.vue';
import Section2 from '@/components/report/section2.vue';
import Section3 from '@/components/report/section3.vue';
import Section4 from '@/components/report/section4.vue';
import Section5 from '@/components/report/section5.vue';

export default {
    components: {
        Section1,
        Section2,
        Section3,
        Section4,
        Section5,
    },
    data() {
        return {};
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.card {
    background: #fff;
    border-width: 2px 0;
    border-style: solid;
    border-color: #e2e2e2;

    .title {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &::after {
            content: "";
            display: block;
            height: 5px;
            width: 100%;
            border-radius: 100px;
            background: var(--color-highlight);
        }
    }
}
.introduction {
    position: relative;
    padding: 2rem 2rem;
    width: 100%;
    overflow: hidden;
    margin-top: 58px;
}

.introduction-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, transparent, #fff 33.3333%, #fff 66.6666%, transparent);
    z-index: 10;
}

.introduction-content {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    z-index: 20;
    .introduction-title {
        color: #00846d;
        margin-bottom: 0.3em;
        font-weight: 700;
        text-align: center;
    }
    .introduction-description {
        color: #111a24;
        font-size: 0.8em;
        line-height: 2;
        text-align: center;
        font-weight: 500;
    }
}

.border {
    position: absolute;
    left: 50%;
    height: 2px;
    background: linear-gradient(to right, transparent 0%, #00a084 50%, transparent 100%);
    z-index: 30;
    width: calc(100% - 4rem);
    transform: translateX(-50%);
}

@media (min-width: 768px) {
    .introduction{
        padding: 2.5rem 1rem;
    }
    .introduction-content {
        .introduction-description {
            font-size: 1.2em;
        }
    }
}
</style>
