<template>
    <div
        class="py-10 px-5 py-sm-15"
        style="max-width: 1000px; margin: auto"
    >
        <h1>成績報告書中透明公開</h1>
        <v-row class="mt-5">
            <v-col
                v-for="(item, i) in list"
                :key="i"
                class="rwd-sm-3 rwd-6"
            >
                <v-card
                    outlined
                    class="rounded-xl overflow-hidden"
                    style="border: 5px solid rgba(0, 160, 132, 0.45); height: 100%"
                >
                    <div
                        class="d-flex align-center justify-center py-5 rounded-0"
                        style="background: var(--color-primary)"
                    >
                        <img
                            :src="item.img"
                            width="70%"
                        >
                    </div>
                    <v-card-text>
                        <b>
                            <pre
                                class="text-center"
                                style="color: var(--color-primary)"
                            >{{
                                item.caption
                            }}</pre>
                        </b>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [
                {
                    // eslint-disable-next-line global-require
                    img: require('@/assets/report/ICON1.png'),
                    caption: '各科學習能力\n指標分析圖',
                },
                {
                    // eslint-disable-next-line global-require
                    img: require('@/assets/report/ICON2.png'),
                    caption: '個人答題狀況',
                },
                {
                    // eslint-disable-next-line global-require
                    img: require('@/assets/report/ICON3.png'),
                    caption: '各單元\n前標、均標與後標',
                },
                {
                    // eslint-disable-next-line global-require
                    img: require('@/assets/report/ICON4.png'),
                    caption: '客製化評語及\n學習建議',
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
h1 {
    color: #fff;
    text-align: center;
    border-radius: 100px;
    padding: 0.3em 0.5em;
    letter-spacing: 5px;
    background-color: var(--color-highlight);
    background-image: -webkit-repeating-linear-gradient(
        -60deg,
        rgba(255, 255, 255, 0.25) 0,
        rgba(255, 255, 255, 0.25) 2px,
        var(--color-highlight) 0,
        var(--color-highlight) 15px
    );
    background-image: repeating-linear-gradient(
        -60deg,
        rgba(255, 255, 255, 0.25) 0,
        rgba(255, 255, 255, 0.25) 2px,
        var(--color-highlight) 0,
        var(--color-highlight) 15px
    );
}
pre {
    font-size: 1em;
    line-height: 1.5;
}
@media (min-width: 768px) {
    pre {
        font-size: 1.4em;
    }
}
</style>
